@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: overlay;
  scrollbar-width: thin;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: contain;
  background-color: #fbfdf8;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

#root {
  width: 100vw;
  height: 100vh;
}

.hideHeader .ant-picker-calendar-header {
  display: none;
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.full-wdith {
  width: 100%;
}

.square-container {
  background-color: white;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
}

.recharts-tooltip-wrapper {
  z-index: 100;
}
.color-green {
  color: green;
}
.scroll-container {
  overflow-y: hidden;
  overflow-x: hidden;
}
.scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
}
.gray-background {
  background-color: #eeeeee;
}

.gray-text {
  color: gray;
}

.self-scroll {
  overflow-y: auto;
}
.self-scroll-x {
  overflow-x: auto;
}
.product-category-tab {
  max-width: 100px;
  text-overflow: ellipsis;
}

.line-through {
  text-decoration: line-through;
}

.selectable:hover {
  cursor: pointer;
  background-color: #cfe9d9;
}

.selected {
  background-color: #cfe9d9;
}

.text-center {
  text-align: center;
}

.single-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-screen-height {
  height: 100vh;
}

.full-page {
  width: 100%;
  height: 100%;
}

.flex {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column; /* Safari */
}
.flex-grow {
  flex-grow: 1;
}
.flex-fill {
  -webkit-flex: 1; /* Safari */
  flex: 1;
}
.flex-row {
  flex-direction: row;
  -webkit-flex-direction: row; /* Safari */
}
.flex-stretch {
  align-items: stretch;
  -webkit-align-items: stretch; /* Safari */
}
.flex-column {
  flex-direction: column;
  -webkit-flex-direction: column; /* Safari */
}
.flex-center {
  -webkit-justify-content: center; /* Safari */
  justify-content: center;
}
.flex-wrap {
  -webkit-flex-wrap: wrap; /* Safari */
  flex-wrap: wrap;
}
.flex-space-between {
  -webkit-justify-content: space-between; /* Safari */
  justify-content: space-between;
}
.flex-space-around {
  -webkit-justify-content: space-around; /* Safari */
  justify-content: space-around;
}
.flex-align-center {
  align-items: center;
  -webkit-align-items: center; /* Safari */
}
.flex-align-start {
  align-items: flex-start;
  -webkit-align-items: flex-start; /* Safari */
}
.flex-align-end {
  align-items: flex-end;
  -webkit-align-items: flex-end; /* Safari */
}
.flex-end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end; /* Safari */
}
.margin {
  margin: 16px;
}
.margin-half {
  margin: 8px;
}
.margin-top {
  margin-top: 16px;
}
.margin-top-half {
  margin-top: 8px;
}
.margin-bottom {
  margin-bottom: 16px;
}
.margin-bottom-half {
  margin-bottom: 8px;
}
.margin-horizontal {
  margin: 0px 16px;
}
.margin-horizontal-half {
  margin: 0px 8px;
}
.margin-vertical {
  margin: 16px 0px;
}
.margin-right {
  margin-right: 16px;
}
.margin-left {
  margin-left: 16px;
}
.padding-half {
  padding: 8px;
}
.padding {
  padding: 16px;
}
.padding-top {
  padding-top: 16px;
}
.padding-top-half {
  padding-top: 8px;
}
.padding-bottom {
  padding-bottom: 16px;
}
.padding-bottom-half {
  padding-bottom: 8px;
}
.padding-left {
  padding-left: 16px;
}
.padding-left-half {
  padding-left: 8px;
}
.padding-right {
  padding-right: 16px;
}
.padding-right-half {
  padding-right: 8px;
}
.padding-horizontal {
  padding: 0px 16px;
}
.padding-horizontal-half {
  padding: 0px 8px;
}
.padding-vertical {
  padding: 16px 0px;
}
.padding-vertical-half {
  padding: 8px 0px;
}
.font-white {
  color: white;
}

/* Global override for all List Items */
.ant-list-item {
  padding-left: 16 !important;
  padding-right: 16 !important;
}